<template>
    <v-container class="px-12">
        <v-form v-model="validReportForm">
            <span class="primary--text font-size-14 text-capitalize">{{
                $t('select_course')
            }}</span>
            <v-select
                v-model="courseSelected"
                :items="courses"
                item-text="name"
                item-value="id"
                clearable
                :rules="courseInputValidations"
                :label="$t('resources.course_to_download')"
                single-line
            >
            </v-select>
            <span class="primary--text font-size-14 text-capitalize">{{
                $t('resources.select_report_month')
            }}</span>
            <v-menu
                ref="monthMenu"
                v-model="monthMenu"
                :close-on-content-click="false"
                :return-value.sync="selectedMonth"
                transition="scale-transition"
                offset-y
                min-width="auto"
            >
                <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        v-model="selectedMonth"
                        :label="$t('resources.report_month')"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        :rules="monthInputValidations"
                        single-line
                    ></v-text-field>
                </template>
                <v-date-picker
                    v-model="selectedMonth"
                    no-title
                    scrollable
                    type="month"
                    :max="new Date().toISOString()"
                >
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="monthMenu = false" class="text-capitalize">
                        {{ $t('cancel') }}
                    </v-btn>
                    <v-btn
                        text
                        color="primary"
                        @click="$refs.monthMenu.save(selectedMonth)"
                        class="text-capitalize"
                    >
                        {{ $t('select') }}
                    </v-btn>
                </v-date-picker>
            </v-menu>
        </v-form>
        <v-btn
            :disabled="!validReportForm"
            color="secondary"
            outlined
            rounded
            class="text-capitalize mt-4"
            @click="loadPdf"
            :loading="loadingPdf"
        >
            <v-icon class="mr-2">mdi-file-download-outline</v-icon>{{ $t('download') }}
        </v-btn>
        <v-dialog v-model="dialog" persistent max-width="290">
            <v-card>
                <v-toolbar class="text-body-1" color="primary" dark>
                    <v-row justify="center">
                        {{ $t('resources.monthly_report') }}
                    </v-row>
                </v-toolbar>
                <v-card-text class="mt-12 mb-6">
                    <v-row justify="center">
                        {{ $t('resources.monthly_report_no_data') }}
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="black" text @click="dialog = false">
                        {{ $t('resources.accept') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <img
            :src="logoImg"
            id="logo-report"
            max-width="100"
            alt="msg icon"
            style="visibility: hidden"
        />
    </v-container>
</template>

<script>
import logoImg from '@/assets/img/brand/logo.png'
import is from 'is_js'
import { mapState } from 'vuex'
import * as cover from './pdfReports/cover'
import * as detailByStudent from './pdfReports/detailByStudent'
import MonthlyReportService from '../../services/monthlyReport'

import AnalyticEventsMixin from '@/mixins/analyticEvents' //eslint-disable-line
import { AnalyticEventTypes } from '../../constants/AnalyticEventTypes'

const pdfmake = require('pdfmake/build/pdfmake')
const pdffonts = require('pdfmake/build/vfs_fonts.js')

pdfmake.vfs = pdffonts.pdfMake.vfs

export default {
    name: 'MonthlyReport',
    mixins: [AnalyticEventsMixin],
    data: () => ({
        validReportForm: false,
        courseSelected: null,
        monthMenu: false,
        selectedMonth: null,
        dialog: false,
        reportData: null,
        loadingPdf: false,
        logoImg,
        coverGenerator: null,
        detailByStudentGenerator: null,
        logoReportBase64: null,
    }),
    computed: {
        ...mapState({
            courses: (state) => state.userData.courses,
            user: (state) => state.userData.user,
        }),
        monthInputValidations() {
            return [(v) => !!v || this.$t('resources.must_select_month')]
        },
        courseInputValidations() {
            return [(v) => !!v || this.$t('resources.must_select_course')]
        },
    },
    created() {
        this.coverGenerator = cover.default
        this.detailByStudentGenerator = detailByStudent.default
    },
    mounted() {
        const logoReportSrc = document.getElementById('logo-report').src
        this.toDataURL(logoReportSrc, (dataURL) => {
            this.logoReportBase64 = dataURL
        })
    },
    methods: {
        buildReportData(data) {
            const course = this.courses.find((el) => el.id === this.courseSelected)
            const levels = this.$t('leveling.leveling_system_params')
            const level = levels.find((el) => el.level === data.level)
            this.reportData = {
                course: course ? course.name : '--',
                dateFrom: data.dateFrom,
                dateTo: data.dateTo,
                level: data.level,
                levelDescription: level.description,
                readersSegment1: data.readers.filter((el) => el.tier === 1),
                readersSegment2: data.readers.filter((el) => el.tier === 2),
                readersSegment3: data.readers.filter((el) => el.tier === 3),
                totals: data.readers.find((el) => el.tier === 0),
                teacher: `${this.toCapitalize(this.user.firstname)} ${this.toCapitalize(
                    this.user.lastname
                )}`,
                school: course ? course.school.name : '--',
            }
        },
        async getData() {
            return MonthlyReportService.generateReport(
                this.courseSelected,
                `${this.selectedMonth}-01`
            )
                .then((response) => {
                    this.buildReportData(response.content)
                })
                .catch((error) => console.error(error))
        },
        async loadPdf() {
            this.loadingPdf = true
            await this.getData()
            if (
                this.reportData.readersSegment1.length > 0 ||
                this.reportData.readersSegment2.length > 0 ||
                this.reportData.readersSegment3.length > 0
            ) {
                this.renderPdf()
                this.createAnalyticEvent(AnalyticEventTypes.DownloadMonthlyReport)
            } else {
                this.loadingPdf = false
                this.dialog = true
            }
        },
        renderPdf() {
            const configuration = {
                pageMargins: [40, 60, 40, 40],
                pageOrientation: 'landscape',
                pageSize: 'LETTER',
                info: {
                    title: this.$t('resources.monthly_report'),
                    author: 'BeeReaders',
                    subject: this.$t('resources.monthly_report'),
                },
                defaultStyle: {
                    fontSize: 12,
                    font: 'Roboto',
                    lineHeight: 1.2,
                },
                background: {
                    canvas: [
                        {
                            type: 'rect',
                            x: 0,
                            y: 0,
                            w: 792,
                            h: 612,
                            color: '#FFF9ED',
                        },
                    ],
                },
                header: {
                    image: this.logoReportBase64,
                    width: 80,
                    height: 32,
                    aligment: 'center',
                    margin: [632, 20, 0, 0],
                },
                footer: (currentPage) => {
                    return {
                        margin: [80, 2, 80, 0],
                        columns: [
                            {
                                text: this.$t('reporting.monthly_report_version'),
                                alignment: 'left',
                                color: 'black',
                            },
                            {
                                text: currentPage.toString(),
                                alignment: 'right',
                                color: 'black',
                            },
                        ],
                    }
                },
                content: [
                    this.coverGenerator.generateCover(this),
                    this.detailByStudentGenerator.generateDetailByStudent(this),
                ],
            }
            if (is.ie('<10')) {
                const pdfDocGenerator = pdfmake.createPdf(configuration)
                pdfDocGenerator.getBlob((blob) => {
                    window.navigator.msSaveBlob(
                        blob,
                        `${this.reportData.course} -- ${this.selectedMonth}.pdf`
                    )
                })
            } else {
                pdfmake
                    .createPdf(configuration)
                    .download(`${this.reportData.course} -- ${this.selectedMonth}`)
            }
            this.loadingPdf = false
        },
        toCapitalize(text) {
            return this.$options.filters.capitalize(text)
        },
        toDataURL(url, callback) {
            const xhr = new XMLHttpRequest()
            xhr.open('get', url)
            xhr.responseType = 'blob'
            xhr.onload = () => {
                const fr = new FileReader()
                fr.onload = (e) => {
                    callback(e.currentTarget.result)
                }
                fr.readAsDataURL(xhr.response)
            }
            xhr.send()
        },
    },
}
</script>
