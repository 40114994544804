export default {
    generateCover(mainVueObject: any) {
        const main = mainVueObject;
        const months = main.$t('resources.months')
        const selectedMonthIndex = Number(main.selectedMonth.substring(5, 7)) - 1
        const month = months[selectedMonthIndex]
        const selectedYear = main.selectedMonth.substring(0, 4)
        const lastDayOfMonth = main.reportData.dateTo.substring(8, 10)

        return [
            {
                alignment: 'left',
                bold: true,
                color: 'black',
                fontSize: 28,
                margin: [40, 5, 0, 0],
                text: `${main.$t('resources.monthly_report')}: ${month}`,
            },
            {
                columns: [
                    {
                        columns: [
                            {
                                alignment: 'left',
                                bold: true,
                                color: 'black',
                                margin: [0, 0, 0, 0],
                                text: `${main.$t('resources.school')}:`,
                                width: 'auto',
                            },
                            {
                                alignment: 'left',
                                color: 'black',
                                margin: [3, 0, 0, 0],
                                text: main.reportData.school,
                                width: 'auto',
                            },
                        ],
                        width: '*',
                    },
                    {
                        columns: [
                            {
                                alignment: 'left',
                                bold: true,
                                color: 'black',
                                margin: [0, 0, 0, 0],
                                text: `${main.$t('resources.reported_period')}:`,
                                width: 'auto',
                            },
                            {
                                alignment: 'left',
                                color: 'black',
                                margin: [3, 0, 0, 0],
                                text: `1 ${main.$t('resources.to_the')} ${lastDayOfMonth} ${main.$t('resources.of')} ${month.toLowerCase()} ${selectedYear}`,
                                width: 'auto',
                            },
                        ],
                        width: '*',
                    },
                ],
                margin: [40, 5, 0, 0],
            },
            {
                columns: [
                    {
                        columns: [
                            {
                                alignment: 'left',
                                bold: true,
                                color: 'black',
                                margin: [0, 0, 0, 0],
                                text: `${main.$t('resources.course_section')}:`,
                                width: 'auto',
                            },
                            {
                                alignment: 'left',
                                color: 'black',
                                margin: [3, 0, 0, 0],
                                text: main.reportData.course,
                                width: 'auto',
                            },
                        ],
                        width: '*',
                    },
                    {
                        columns: [
                            {
                                alignment: 'left',
                                bold: true,
                                color: 'black',
                                margin: [0, 0, 0, 0],
                                text: `${main.$t('resources.teacher_in_charge')}:`,
                                width: 'auto',
                            },
                            {
                                alignment: 'left',
                                color: 'black',
                                margin: [3, 0, 0, 0],
                                text: main.reportData.teacher,
                                width: 'auto',
                            },
                        ],
                        width: '*',
                    },
                ],
                margin: [40, 5, 0, 0],
            },
            {
                layout: {
                    border: [false, false, false, false],
                    defaultBorder: '',
                    fillColor: (rowIndex: number, node: any, columnIndex: number) => {
                        return (columnIndex === 0) ? '#594F73' : '#FCE3BA'
                    },
                },
                margin: [40, 30, 40, 0],
                pageBreak: 'after',
                table: {
                    widths: ['25%', '75%'],
                    body: [
                        [
                            {
                                alignment: 'center',
                                bold: true,
                                color: 'white',
                                fontSize: 16,
                                margin: [0, 20, 0, 0],
                                text: main.$t('resources.current_level'),
                            },
                            {
                                alignment: 'center',
                                color: 'black',
                                margin: [20, 30, 20, 0],
                                text: main.reportData.levelDescription,
                            }
                        ],
                        [
                            {
                                alignment: 'center',
                                bold: true,
                                color: 'white',
                                fontSize: 24,
                                margin: [0, -25, 0, 15],
                                text: main.reportData.level.toString(),
                            },
                            {
                                text: ``,
                            }
                        ],
                    ]
                },
            },
        ]
    }
}
