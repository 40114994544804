import Services from './base-services'

const service = new Services('monthlyReport')
import mapParameters from '../utils/map-parameters'

export default {
    generateReport(courseId: number, date: string) {
        const params = mapParameters({
            courseId: courseId ? courseId : null,
            date: date ? date : null,
        })
        return service.get('generateReport', params)
    },
}
