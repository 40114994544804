const getDescriptionTableItem = (text: string) => {
    return {
        alignment: 'left',
        fontSize: 9,
        text: text,
    }
}

const getTableHeader = (
    alignment: string,
    colSpan: number,
    rowSpan: number,
    text: string,
    marginTop: number = 0
) => {
    return {
        alignment,
        bold: true,
        border: [true, true, true, true],
        colSpan,
        fontSize: 9,
        margin: [0, marginTop, 0, 0],
        rowSpan,
        text,
    }
}

const getTableItem = (alignment: string, text: string, border: boolean = true) => {
    return {
        alignment: alignment === '' ? 'center' : alignment,
        border: [border, border, border, border],
        fontSize: 9,
        text: text,
    }
}

const getTableTotalsItem = (alignment: string, text: string, border: boolean = true) => {
    return {
        alignment: 'center',
        bold: true,
        border: [border, border, border, border],
        fontSize: 9,
        text,
    }
}
const getTableItemOne = (alignment: string, text: string, border: boolean = true) => {
    return {
        alignment: alignment === '' ? 'center' : alignment,
        border: [border, border, border, border],
        fontSize: 9,
        rowSpan: 2,
        margin: [0, 20, 0, 0],
        text: text,
    }
}

const getTableItemTwo = (alignment: string, text: string, border: boolean = true) => {
    return {
        alignment: alignment === '' ? 'center' : alignment,
        border: [border, border, border, border],
        fontSize: 9,
        rowSpan: 1,
        margin: [0, 5, 0, 0],
        text: text,
    }
}

const getTableSegment = (main: any, index: number) => {
    let rowSpan = 1
    switch (index) {
        case 0:
            rowSpan += main.reportData.readersSegment1.length
            break
        case 1:
            rowSpan += main.reportData.readersSegment2.length
            break
        case 2:
            rowSpan += main.reportData.readersSegment3.length
            break
        default:
            break
    }
    return {
        border: [true, true, true, false],
        layout: {
            border: [false, false, false, false],
            defaultBorder: '',
        },
        margin: [0, 0, 0, 0],
        rowSpan: rowSpan + (index === 2 ? 1 : 0),
        table: {
            widths: ['100%'],
            body: [
                [
                    {
                        alignment: 'center',
                        bold: true,
                        fontSize: 9,
                        margin: [-1, 10, -1, 0],
                        text: `${main.$t('resources.segment')} ${(index + 1).toString()}`,
                    },
                ],
                [
                    {
                        alignment: 'center',
                        fontSize: 9,
                        margin: [0, 0, 0, 0],
                        text: `${main.$t(`resources.segment_values[${index}]`)}`,
                    },
                ],
            ],
        },
    }
}

const getTableStudentEmpty = () => {
    const emptyStudent = []
    const totalColumnsOnTable = 11

    for (let i = 0; i < totalColumnsOnTable; i++) {
        emptyStudent.push({
            alignment: 'center',
            border: [true, true, true, true],
            margin: [0, 20, 0, 0],
            text: '---',
        })
    }
    return emptyStudent
}

const getTableStudent = (main: any, student: any) => {
    return [
        getTableItem(
            'left',
            `${main.toCapitalize(student.firstname.split(' ')[0])} ${main.toCapitalize(
                student.lastname.split(' ')[0]
            )}`
        ),
        getTableItem('', `${student.averageDiagnostic}%`),
        getTableItem('', student.level.toString()),
        getTableItem('', `${student.bookPages}%`),
        getTableItem('', `${student.bookComprehension}%`),
        getTableItem('', student.challengeCount.toString()),
        getTableItem('', `${student.challengeComprehension}%`),
        getTableItem('', `${student.skillLocate}%`),
        getTableItem('', `${student.skillIntegrate}%`),
        getTableItem('', `${student.skillReflect}%`),
        getTableTotalsItem('', `${student.average}%`),
    ]
}

const getTableStudentOne = (main: any, student: any) => {
    return [
        getTableItemOne(
            'left',
            `${main.toCapitalize(student.firstname.split(' ')[0])} ${main.toCapitalize(
                student.lastname.split(' ')[0]
            )}`
        ),
        getTableItemOne('', `${student.averageDiagnostic}%`),
        getTableItemOne('', student.level.toString()),
        getTableItemOne('', `${student.bookPages}%`),
        getTableItemOne('', `${student.bookComprehension}%`),
        getTableItemOne('', student.challengeCount.toString()),
        getTableItemOne('', `${student.challengeComprehension}%`),
        getTableItemOne('', `${student.skillLocate}%`),
        getTableItemOne('', `${student.skillIntegrate}%`),
        getTableItemOne('', `${student.skillReflect}%`),
        getTableItemOne('', `${student.average}%`),
    ]
}
const getTableStudentTwo = (main: any, student: any) => {
    return [
        getTableItemTwo(
            'left',
            `${main.toCapitalize(student.firstname.split(' ')[0])} ${main.toCapitalize(
                student.lastname.split(' ')[0]
            )}`
        ),
        getTableItemTwo('', `${student.averageDiagnostic}%`),
        getTableItemTwo('', student.level.toString()),
        getTableItemTwo('', `${student.bookPages}%`),
        getTableItemTwo('', `${student.bookComprehension}%`),
        getTableItemTwo('', student.challengeCount.toString()),
        getTableItemTwo('', `${student.challengeComprehension}%`),
        getTableItemTwo('', `${student.skillLocate}%`),
        getTableItemTwo('', `${student.skillIntegrate}%`),
        getTableItemTwo('', `${student.skillReflect}%`),
        getTableItemTwo('', `${student.average}%`),
    ]
}

const getTableRowsBySegment = (main: any, index: number) => {
    let data
    let result: any = []
    switch (index) {
        case 0:
            data = main.reportData.readersSegment1
            break
        case 1:
            data = main.reportData.readersSegment2
            break
        case 2:
            data = main.reportData.readersSegment3
            break
        default:
            break
    }
    if (data.length === 0) {
        result = [[getTableSegment(main, index), ...getTableStudentEmpty()]]
    }
    if (data.length === 1) {
        result = [
            [
                {
                    alignment: 'center',
                    bold: true,
                    border: [true, true, true, false],
                    color: 'white',
                    fontSize: 9,
                    margin: [0, 10, 0, 0],
                    text: `${main.$t('resources.segment')} ${(index + 1).toString()}`,
                },
                ...getTableStudentOne(main, data[0]),
            ],
            [
                {
                    alignment: 'center',
                    border: [true, false, true, true],
                    color: 'white',
                    fontSize: 9,
                    margin: [0, 0, 0, 10],
                    text: `${main.$t(`resources.segment_values[${index}]`)}`,
                },
                {},
                {},
                {},
                {},
                {},
                {},
                {},
                {},
                {},
                {},
                {},
            ],
        ]
    }
    if (data.length === 2) {
        result = [
            [
                {
                    alignment: 'center',
                    bold: true,
                    border: [true, true, true, false],
                    color: 'white',
                    fontSize: 9,
                    margin: [0, 10, 0, 0],
                    text: `${main.$t('resources.segment')} ${(index + 1).toString()}`,
                },
                ...getTableStudentTwo(main, data[0]),
            ],
            [
                {
                    alignment: 'center',
                    border: [true, false, true, true],
                    color: 'white',
                    fontSize: 9,
                    margin: [0, 0, 0, 10],
                    text: `${main.$t(`resources.segment_values[${index}]`)}`,
                },
                ...getTableStudentTwo(main, data[1]),
            ],
        ]
    }
    if (data.length > 2) {
        result = [
            [
                {
                    border: [true, true, true, false],
                    text: '',
                },
                ...getTableStudent(main, data[0]),
            ],
            [
                {
                    alignment: 'center',
                    bold: true,
                    border: [true, false, true, false],
                    color: 'white',
                    fontSize: 9,
                    margin: [0, -5, 0, 0],
                    text: `${main.$t('resources.segment')} ${(index + 1).toString()}`,
                },
                ...getTableStudent(main, data[1]),
            ],
            [
                {
                    alignment: 'center',
                    border: [true, false, true, false],
                    color: 'white',
                    fontSize: 9,
                    margin: [0, -5, 0, 0],
                    text: `${main.$t(`resources.segment_values[${index}]`)}`,
                },
                ...getTableStudent(main, data[2]),
            ],
        ]
        for (let i = 3; i < data.length; i++) {
            result.push([
                {
                    border: [true, false, true, false],
                    text: '',
                },
                ...getTableStudent(main, data[i]),
            ])
        }
    }
    return result
}

export default {
    generateDetailByStudent(mainVueObject: any) {
        const main = mainVueObject

        const validateQty = (qty: number) => {
            if (qty === 0) {
                return 1
            }
            if (qty === 1) {
                return 2
            }
            return qty
        }

        const rowQtyWithSegment1 = validateQty(main.reportData.readersSegment1.length) + 2
        const rowQtyWithSegment12 =
            validateQty(main.reportData.readersSegment2.length) + rowQtyWithSegment1
        const rowQtyWithSegment123 =
            validateQty(main.reportData.readersSegment3.length) + rowQtyWithSegment12

        const tableTitle = {
            alignment: 'left',
            bold: true,
            color: 'black',
            fontSize: 18,
            margin: [40, 5, 0, 0],
            text: main.$t('resources.detail_by_student'),
        }

        const tableDescription = {
            layout: {
                border: [false, false, false, false],
                defaultBorder: '',
                fillColor: () => {
                    return 'white'
                },
            },
            margin: [40, 10, 40, 0],
            table: {
                body: [
                    [
                        getDescriptionTableItem(main.$t('resources.di_report')),
                        getDescriptionTableItem(main.$t('resources.challenges_number')),
                    ],
                    [
                        getDescriptionTableItem(main.$t('resources.cl_report')),
                        getDescriptionTableItem(main.$t('resources.f_report')),
                    ],
                    [
                        getDescriptionTableItem(main.$t('resources.percent_pages')),
                        getDescriptionTableItem(main.$t('resources.i_report')),
                    ],
                    [
                        getDescriptionTableItem(main.$t('resources.percent_reading')),
                        getDescriptionTableItem(main.$t('resources.r_report'))
                    ]
                ],
                widths: ['50%', '50%'],
            },
        }

        const tableWarnings = {
            layout: {
                border: [false, false],
                defaultBorder: '',
                fillColor: () => {
                    return 'white'
                },
            },
            margin: [40, 10, 40, 0],
            table: {
                body: [
                    [
                        getDescriptionTableItem(main.$t('resources.warning_percent_cl'))
                    ],
                    [
                        getDescriptionTableItem(main.$t('resources.warning_quantity_answer_challenge'))
                    ],
                ],
                widths: ['100%'],
            },
        }

        const tableBody = {
            layout: {
                border: [true, true, true, true],
                defaultBorder: '',
                fillColor: (rowIndex: number, node: any, columnIndex: number) => {
                    let color = ''
                    if (rowIndex >= 2 && columnIndex === 0) {
                        if (rowIndex >= 2 && rowIndex <= rowQtyWithSegment1) {
                            color = '#00DBAA'
                        }
                        if (rowIndex >= rowQtyWithSegment1 && rowIndex <= rowQtyWithSegment12) {
                            color = '#FEDA70'
                        }
                        if (rowIndex >= rowQtyWithSegment12 && rowIndex < rowQtyWithSegment123) {
                            color = '#594F73'
                        }
                    }

                    if (rowIndex < 2 && columnIndex > 0) {
                        color = '#FCE3BA'
                    }
                    if (rowIndex === rowQtyWithSegment123 && columnIndex > 0) {
                        color = '#FCE3BA'
                    }
                    if (columnIndex === 11 || columnIndex === 5 || columnIndex === 7) {
                        return '#FFC000'
                    }
                    if (
                        rowIndex === 1 &&
                        (columnIndex === 8 || columnIndex === 9 || columnIndex === 10)
                    ) {
                        return '#8DB3E2'
                    }
                    return color
                },
            },
            margin: [40, 30, 40, 0],
            table: {
                body: [
                    //Header
                    [
                        {
                            border: [false, false, false, false],
                            rowSpan: 2,
                            text: '',
                        },
                        getTableHeader('center', 1, 2, main.$t('resources.name'), 10),
                        getTableHeader(
                            'center',
                            1,
                            2,
                            main.$t('resources.report_header_abbreviation[0]'),
                            10
                        ),
                        getTableHeader(
                            'center',
                            1,
                            2,
                            main.$t('resources.report_header_abbreviation[7]'),
                            10
                        ),
                        getTableHeader('center', 2, 1, main.$t('resources.books')),
                        {},
                        getTableHeader('center', 2, 1, main.$t('resources.challenges')),
                        {},
                        getTableHeader('center', 3, 1, main.$t('resources.skills')),
                        {},
                        {},
                        getTableHeader('center', 1, 2, main.$t('resources.total_average'), 10),
                    ],
                    [
                        {},
                        {},
                        {},
                        getTableHeader(
                            'center',
                            1,
                            1,
                            main.$t('resources.report_header_abbreviation[7]')
                        ),
                        getTableHeader(
                            'center',
                            1,
                            1,
                            main.$t('resources.report_header_abbreviation[1]')
                        ),
                        getTableHeader(
                            'center',
                            1,
                            1,
                            main.$t('resources.report_header_abbreviation[2]')
                        ),
                        getTableHeader(
                            'center',
                            1,
                            1,
                            main.$t('resources.report_header_abbreviation[3]')
                        ),
                        getTableHeader(
                            'center',
                            1,
                            1,
                            main.$t('resources.report_header_abbreviation[2]')
                        ),
                        getTableHeader(
                            'center',
                            1,
                            1,
                            main.$t('resources.report_header_abbreviation[4]')
                        ),
                        getTableHeader(
                            'center',
                            1,
                            1,
                            main.$t('resources.report_header_abbreviation[5]')
                        ),
                        getTableHeader(
                            'center',
                            1,
                            1,
                            main.$t('resources.report_header_abbreviation[6]')
                        ),
                        {},
                    ],
                    // Body
                    ...getTableRowsBySegment(main, 0),
                    ...getTableRowsBySegment(main, 1),
                    ...getTableRowsBySegment(main, 2),
                    // Totals
                    [
                        {
                            border: [false, true, false, false],
                            text: '',
                        },
                        getTableTotalsItem('', main.$t('resources.average_and_totals')),
                        getTableTotalsItem('', `${main.reportData.totals.averageDiagnostic}%`),
                        getTableTotalsItem('', main.reportData.totals.level.toString()),
                        getTableTotalsItem('', `${main.reportData.totals.bookPages}%`),
                        getTableTotalsItem('', `${main.reportData.totals.bookComprehension}%`),
                        getTableTotalsItem('', main.reportData.totals.challengeCount.toString()),
                        getTableTotalsItem('', `${main.reportData.totals.challengeComprehension}%`),
                        getTableTotalsItem('', `${main.reportData.totals.skillLocate}%`),
                        getTableTotalsItem('', `${main.reportData.totals.skillIntegrate}%`),
                        getTableTotalsItem('', `${main.reportData.totals.skillReflect}%`),
                        getTableTotalsItem('', `${main.reportData.totals.average}%`),
                    ],
                ],
                headerRows: 2,
                widths: ['9%', '24%', '6%', '6%', '6%', '6%', '6%', '6%', '6%', '6%', '6%', '13%'],
            },
        }

        return [tableTitle, tableDescription, tableWarnings, tableBody]
    },
}
